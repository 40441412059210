/* Product Container */
.product {
  height: auto;
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* Product image container */
.imgbox {
  height: 100%;
  box-sizing: border-box;
}

.imgbox img {
  display: block;
  width: 100% !important;
  margin: 0 auto 55px;
}

/* Product details */
.product.show-details .product-meta {
  background-color: #fff;
  border-radius: 8px;
  bottom: 20px;
  padding: 10px;
}

.product-meta {
  position: absolute;
  width: calc(100%);
  left: 0px;
  background: #fff;
  transition: 0.75s;
  bottom: 0;
}

.product-meta.with-extra {
  bottom: -30px;
}

.product-header,
.product-header h3 {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.4rem;
  width: 100%;
}

.product-header {
  display: block;
  cursor: pointer;
}
