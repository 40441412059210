@import "./site.css";
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5rem !important;
  background: #ffffff;
}

.text-cursive {
  font-family: "Monsieur La Doulaise", cursive !important;
}
