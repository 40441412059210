/* Image styling */
.img-list-cont .item {
  display: none;
}
.img-list-cont .item.active {
  display: block;
}

@media (max-width: 767.98px) {
  .item .img-fluid {
    width: 25%;
  }
}

/* Overriding bootstrap border thickness */
.img-display.border {
  border: 1.5px solid !important;
}

@media (min-width: 767.98px) {
  .img-display-container .img-fluid {
    height: 550px;
  }
  .item .img-fluid {
    min-width: 150px;
  }
}

.img-fluid.active {
  color: #000;
  border: 1px solid #000;
  z-index: 10 !important;
}
