/* Separator */
.item-brand {
  position: relative;
  padding-left: 3.125rem;
  margin-bottom: 0.3125rem;
  display: inline-block;
  font-size: 0.75rem;
  font-family: "Muli", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.item-brand::before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 1.875rem;
  height: 1px;
  background: #868e96;
}

/* Item price */
.item-price {
  font-size: 1.125rem;
  color: #000;
}
