/* Just for this page we fall back to previous paddings and margins */
.container-md,
.row > * {
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
