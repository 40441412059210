#wp-link {
  background-color: #000;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  text-align: center;
  z-index: 99999999;
}

#wp-link .fa-whatsapp {
  margin-top: 20px;
}
