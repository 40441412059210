.product-price {
  display: block;
  cursor: pointer;
}

.product-price,
.product-stock {
  font-size: 12px;
}

.product-stock {
  display: inline-block;
}
