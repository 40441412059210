header .bg-layer {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-quote {
  z-index: 10;
  font-size: 80%;
  padding-top: 20px;
}

header.product {
  display: flex;
  position: relative;
  height: 300px;
}

header span {
  line-height: 0.98;
  max-width: 80%;
  font-size: 30px;
}

@media (min-width: 767.98px) {
  .header-quote {
    font-size: 1.9rem;
    padding-top: unset;
  }
}

@media (max-width: 767.98px) {
  header.product {
    height: 130px !important;
  }
}
