/* Color buttons style */
label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.btn-group-square {
  margin: 1px 0 0 1px;
}

.btn-group-square .btn.active,
.img-fluid.active {
  color: #000;
  border: 1px solid #000;
  z-index: 10 !important;
}

.btn-group-square .btn {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  float: left;
  padding: 0;
  width: calc(1.5em + 1.875rem + 2px);
  height: calc(1.5em + 1.875rem + 2px);
  margin-top: -1px;
  margin-left: -1px;
  font-size: 0.875rem;
  line-height: calc(1.5em + 1.875rem);
  border: 1px solid #dddddd;
  z-index: 5;
}

.btn-group-colors > .btn .colored-circle {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border: 0.1px solid #ccc;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

.product-meta .btn-group-colors > .btn .colored-circle {
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.product-meta .btn-group-square .btn {
  width: calc(1em + 1rem + 2px);
  height: calc(1em + 1rem + 2px);
  border: 0px solid #dddddd;
  z-index: 5;
}

.product-meta .btn-group-square .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  color: #fff;
  border: 0px solid #fff;
  z-index: 10 !important;
}
